<template>
  <div v-if="homepageEditorialContent" class="editoriale-section">
    <div class="editoriale-section-image-container">
      <SfImage
        :src="
          homepageEditorialContent.images.desktop ||
          '/homepage/editorialeSection/editorialeImg.svg'
        "
        :alt="homepageEditorialContent.images.alt || 'Editorial'"
        image-tag="nuxt-img"
        width="720"
        :height="isDesktop ? '668' : '420'"
        placeholder
        class="editoriale__image"
      />
    </div>
    <div class="editoriale-section-description-container">
      <p class="editoriale-section-description-container__heading">
        {{ homepageEditorialContent.text.pretitle }}
      </p>
      <p class="editoriale-section-description-container__title">
        {{ homepageEditorialContent.text.title }}
      </p>
      <p class="editoriale-section-description-container__description">
        {{ homepageEditorialContent.text.content }}
      </p>
      <CustomButton
        type="tertiary"
        :text="homepageEditorialContent.link.label"
        :link="homepageEditorialContent.link.url"
      />
    </div>
  </div>
  <div v-else class="editoriale-section" />
</template>

<script>
import { SfImage } from '@storefront-ui/vue';
import CustomButton from '~/components/General/CustomButton.vue';
import { useWindow } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { ref } from '@nuxtjs/composition-api';

export default {
  name: 'EditorialeSection',
  components: {
    SfImage,
    CustomButton,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const { isDesktop } = useWindow();
    const homepageEditorialContent = ref();
    homepageEditorialContent.value =
      props.data?.it?.active && locale === 'it' ? props.data.it : props.data;
    return {
      isDesktop,
      homepageEditorialContent,
    };
  },
};
</script>

<style lang="scss" scoped>
.editoriale-section {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--c-grey);
  border-bottom: 1px solid var(--c-grey);
  .editoriale-section-image-container {
    .editoriale__image {
      width: 100%;
      display: block;
    }
  }
  .editoriale-section-description-container {
    padding-top: 2.5rem;
    padding-left: 1.563rem;
    padding-right: 1.563rem;
    padding-bottom: 4.375rem;
    &__heading {
      @include font-14x16-300;
      text-transform: uppercase;
      margin: 0;
    }
    &__title {
      @include font-40x40;
      text-transform: uppercase;
      margin: 0.625rem 0 1.25rem 0;
    }
    &__description {
      @include font-14x19;
      margin: 1.25rem 0;
    }
    .custom-button {
      text-transform: capitalize;
      @include font-14x16;
    }
  }
  @include from-landscape-min {
    flex-direction: row;
    align-items: center;
    .editoriale-section-image-container {
      flex-basis: 50%;
    }
    .editoriale-section-description-container {
      flex-basis: 40%;
      padding-left: 7.188rem;
      padding-right: 6.125rem;
    }
  }
}
</style>

<style lang="scss">
.editoriale__image {
  img {
    width: 100%;
  }
}
</style>
