var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mainBannerContent)?_c('div',{staticClass:"main-banner-wrapper"},[_c('div',{staticClass:"main-banner-content"},[_c('CustomCarousel',{staticClass:"desktop-only",attrs:{"settings":_vm.carouselSettings,"carousel-length":_vm.carouselLength,"grey-chevron":true,"is-desktop":""},on:{"slideChange":function (e) {
          _vm.handleSlideChange(e);
        }}},_vm._l((_vm.mainBannerContent.cards),function(item,index){return _c('CustomCarouselItem',{key:((item.images.alt) + "-" + index)},[_c('div',{staticClass:"carousel-item-content desktop-only"},[_c('a',{attrs:{"href":item.link ? _vm.$fixUrlForCurrentLocale(item.link) : ''}},[_c('SfImage',{attrs:{"alt":item.images.alt || ("Banner Image " + index),"src":item.images.desktop ||
                '/icons/components/product_placeholder.svg',"width":"720","height":"635","image-tag":"nuxt-img","placeholder":"","loading":"eager"}})],1),_vm._v(" "),_c('MainBannerProductInfo',{key:(_vm.bannerCardKey + "-" + index),attrs:{"data":_vm.productsToShow[index] || {},"index":index}})],1)])}),1),_vm._v(" "),_c('CustomCarousel',{staticClass:"mobile-only",attrs:{"settings":_vm.carouselSettings},on:{"slideChange":function (e) {
          _vm.handleSlideChange(e);
        }}},_vm._l((_vm.mainBannerContent.cards),function(item,index){return _c('CustomCarouselItem',{key:((item.images.alt) + "-mobile-" + index)},[_c('div',{staticClass:"carousel-item-content mobile-only"},[_c('a',{attrs:{"href":item.link ? _vm.$fixUrlForCurrentLocale(item.link) : ''}},[_c('SfImage',{attrs:{"alt":item.images.alt || ("Banner Image " + index),"src":item.images.mobile ||
                '/icons/components/product_placeholder.svg',"width":"513","height":"635","image-tag":"nuxt-img","placeholder":"","loading":"eager"}})],1),_vm._v(" "),_c('MainBannerProductInfo',{key:(_vm.bannerCardKey + "-" + index),attrs:{"data":_vm.productsToShow[index] || {},"index":index}})],1)])}),1),_vm._v(" "),(_vm.hasTextBox)?_c(_vm.mainBannerContent.text['link'] ? 'SfLink' : 'div',_vm._b({tag:"component"},'component',
        _vm.mainBannerContent.text['link']
          ? { link: _vm.$fixUrlForCurrentLocale(_vm.mainBannerContent.text['link']) }
          : {}
      ,false),[_c('div',{staticClass:"main-banner-content__title-wrapper"},[_c('span',{staticClass:"main-banner-content__title-small-text"},[_vm._v("\n          "+_vm._s(_vm.mainBannerContent.text['small-text'])+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"main-banner-content__title-title"},[_vm._v("\n          "+_vm._s(_vm.mainBannerContent.text['big-text'])+"\n        ")]),_vm._v(" "),(
            _vm.mainBannerContent.text['cta-left'] ||
            _vm.mainBannerContent.text['cta-right']
          )?_c('div',{staticClass:"main-banner-content__title-wrapper__ctas"},[_c('div',[_c('CustomButton',{attrs:{"type":"tertiary","text":_vm.mainBannerContent.text['cta-left'].text,"link":_vm.mainBannerContent.text['cta-left'].url}})],1),_vm._v(" "),_c('div',[_c('CustomButton',{attrs:{"type":"tertiary","text":_vm.mainBannerContent.text['cta-left'].text,"link":_vm.mainBannerContent.text['cta-left'].url}})],1)]):_vm._e()])]):_vm._e()],1),_vm._v(" "),(_vm.carouselLength > 1)?_c('div',{staticClass:"slider-bullets"},_vm._l((_vm.mainBannerContent.cards),function(item,index){return _c('span',{key:("Bullets" + (item.images.alt) + index),staticClass:"bullet-dot",class:{ 'is-active': _vm.activeSlide === index }})}),0):_vm._e()]):_c('div',{staticClass:"main-banner-wrapper"})}
var staticRenderFns = []

export { render, staticRenderFns }