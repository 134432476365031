<template>
  <div v-if="mainBannerContent" class="main-banner-wrapper">
    <div class="main-banner-content">
      <CustomCarousel
        class="desktop-only"
        :settings="carouselSettings"
        :carousel-length="carouselLength"
        :grey-chevron="true"
        is-desktop
        @slideChange="
          (e) => {
            handleSlideChange(e);
          }
        "
      >
        <CustomCarouselItem
          v-for="(item, index) in mainBannerContent.cards"
          :key="`${item.images.alt}-${index}`"
        >
          <div class="carousel-item-content desktop-only">
            <a :href="item.link ? $fixUrlForCurrentLocale(item.link) : ''">
              <SfImage
                :alt="item.images.alt || `Banner Image ${index}`"
                :src="
                  item.images.desktop ||
                  '/icons/components/product_placeholder.svg'
                "
                width="720"
                height="635"
                image-tag="nuxt-img"
                placeholder=""
                loading="eager"
              />
            </a>
            <MainBannerProductInfo
              :data="productsToShow[index] || {}"
              :index="index"
              :key="`${bannerCardKey}-${index}`"
            />
          </div>
        </CustomCarouselItem>
      </CustomCarousel>
      <CustomCarousel
        class="mobile-only"
        :settings="carouselSettings"
        @slideChange="
          (e) => {
            handleSlideChange(e);
          }
        "
      >
        <CustomCarouselItem
          v-for="(item, index) in mainBannerContent.cards"
          :key="`${item.images.alt}-mobile-${index}`"
        >
          <div class="carousel-item-content mobile-only">
            <a :href="item.link ? $fixUrlForCurrentLocale(item.link) : ''">
              <SfImage
                :alt="item.images.alt || `Banner Image ${index}`"
                :src="
                  item.images.mobile ||
                  '/icons/components/product_placeholder.svg'
                "
                width="513"
                height="635"
                image-tag="nuxt-img"
                placeholder=""
                loading="eager"
              />
            </a>
            <MainBannerProductInfo
              :data="productsToShow[index] || {}"
              :index="index"
              :key="`${bannerCardKey}-${index}`"
            />
          </div>
        </CustomCarouselItem>
      </CustomCarousel>
      <component
        v-if="hasTextBox"
        :is="mainBannerContent.text['link'] ? 'SfLink' : 'div'"
        v-bind="
          mainBannerContent.text['link']
            ? { link: $fixUrlForCurrentLocale(mainBannerContent.text['link']) }
            : {}
        "
      >
        <div class="main-banner-content__title-wrapper">
          <span class="main-banner-content__title-small-text">
            {{ mainBannerContent.text['small-text'] }}
          </span>
          <span class="main-banner-content__title-title">
            {{ mainBannerContent.text['big-text'] }}
          </span>
          <div
            v-if="
              mainBannerContent.text['cta-left'] ||
              mainBannerContent.text['cta-right']
            "
            class="main-banner-content__title-wrapper__ctas"
          >
            <div>
              <CustomButton
                type="tertiary"
                :text="mainBannerContent.text['cta-left'].text"
                :link="mainBannerContent.text['cta-left'].url"
              />
            </div>
            <div>
              <CustomButton
                type="tertiary"
                :text="mainBannerContent.text['cta-left'].text"
                :link="mainBannerContent.text['cta-left'].url"
              />
            </div>
          </div>
        </div>
      </component>
    </div>
    <div v-if="carouselLength > 1" class="slider-bullets">
      <span
        v-for="(item, index) in mainBannerContent.cards"
        :key="`Bullets${item.images.alt}${index}`"
        class="bullet-dot"
        :class="{ 'is-active': activeSlide === index }"
      />
    </div>
  </div>
  <div v-else class="main-banner-wrapper" />
</template>

<script>
import { SfImage, SfLink } from '@storefront-ui/vue';
import { computed, ref, useFetch } from '@nuxtjs/composition-api';
import MainBannerProductInfo from '~/components/Homepage/MainBannerProductInfo.vue';
import CustomCarousel from '~/components/General/CustomCarousel.vue';
import { productData } from '~/helpers/product/productData';
import { useProduct } from '@gemini-vsf/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import CustomButton from '~/components/General/CustomButton.vue';
import CustomCarouselItem from '../General/CustomCarouselItem.vue';

export default {
  name: 'MainBanner',
  components: {
    SfLink,
    SfImage,
    CustomCarousel,
    CustomCarouselItem,
    MainBannerProductInfo,
    CustomButton,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const activeSlide = ref(0);
    const carouselProducts = ref({});
    const { cacheId } = productData();
    const mainBannerContent = ref();
    const productsToShow = ref([]);
    const bannerCardKey = ref(0);
    const hasTextBox = ref(false);
    const carouselLength = computed(() => mainBannerContent?.cards?.length);

    const { search, products: searchResult } = useProduct(cacheId);

    useFetch(async () => {
      try {
        mainBannerContent.value =
          props.data?.it?.active && locale === 'it'
            ? props.data.it
            : props.data;
        const dataCards =
          props.data?.it?.active && locale === 'it'
            ? props.data.it.cards
            : props.data.cards;
        mainBannerContent.value.cards = dataCards.filter(
          (card) => card !== null && card.active
        );
        if (mainBannerContent.value?.cards?.length) {
          const productSkus = [];
          mainBannerContent.value.cards.forEach((product) => {
            if (product.active && product.code) {
              productSkus.push(product.code.trim());
            }
          });
          if (productSkus.length > 0) {
            const baseSearchQuery = {
              filter: {
                sku: {
                  in: productSkus,
                },
              },
            };
            await search({
              queryType: 'LIST',
              ...baseSearchQuery,
              customQuery: {
                products: 'productListCustom',
              },
            });
            if (searchResult.value?.items?.length) {
              mainBannerContent.value.cards.forEach((card) => {
                productsToShow.value.push(
                  searchResult.value.items.find(
                    (item) => item.merchant_sku === card.code.trim()
                  )
                );
              });
              bannerCardKey.value += 1; // forcing sub-component re-render
            }
          }
        }
        hasTextBox.value =
          mainBannerContent.value?.text?.['small-text']?.length > 0 ||
          mainBannerContent.value?.text?.['big-text']?.length > 0;
      } catch {
        console.warn('Unable to fetch block content and/or products details.');
      }
    });

    function handleSlideChange(e) {
      activeSlide.value = e;
    }

    return {
      activeSlide,
      handleSlideChange,
      mainBannerContent,
      carouselProducts,
      productsToShow,
      bannerCardKey,
      hasTextBox,
      carouselLength,
    };
  },
  data() {
    return {
      carouselSettings: {
        type: 'carousel',
        perView: 2,
        breakpoints: {
          1919: {
            perView: 2,
            peek: {
              before: 0,
              after: 0,
            },
          },
          1439: {
            perView: 2,
            peek: {
              before: 0,
              after: 0,
            },
          },
          1199: {
            perView: 2,
            peek: {
              before: 0,
              after: 0,
            },
          },
          1023: {
            perView: 1,
            peek: {
              before: 0,
              after: 0,
            },
          },
          767: {
            perView: 1,
            peek: {
              before: 0,
              after: 0,
            },
          },
          449: {
            perView: 1,
            peek: {
              before: 0,
              after: 0,
            },
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.main-banner-wrapper {
  max-width: var(--desktop-min);
  width: 100vw;
  margin: 0 auto 13.375rem;
  max-height: 635px;
  height: 123.78vw;
  background-color: var(--c-super-light-grey);
  position: relative;
  .main-banner-content {
    max-height: inherit;
    height: inherit;
    background-color: var(--c-super-light-grey);
    position: relative;
    .carousel-item-content {
      position: relative;
    }
    .main-banner-content__title-wrapper {
      @include for-desktop {
        position: absolute;
        width: 22.375rem;
        left: calc(50% - (22.375rem / 2));
        bottom: -10.375rem;
      }
      background-color: var(--c-white);
      text-align: center;
      height: 10.375rem;
      width: 100vw;
      @include flexbox(
        $alignItems: center,
        $justifyContent: center,
        $direction: column,
        $gap: var(--space-xxs)
      );
      .main-banner-content__title- {
        &small-text {
          @include font-14x16-300;
          text-decoration: none;
        }
        &title {
          @include font-40x40;
          text-decoration: none;
        }
      }
      &__ctas {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        margin-top: 0.625rem;
      }
    }
  }
  .slider-bullets {
    display: flex;
    background: transparent;
    height: 20px;
    gap: 0.438rem;
    align-items: center;
    justify-content: center;
    width: fit-content;
    position: absolute;
    bottom: 25px;
    left: 25px;
    .bullet-dot {
      display: inline-block;
      width: 0.438rem;
      height: 0.438rem;
      background-color: var(--c-black-40);
      border-radius: 50%;
      transition: background-color 300ms ease-out;
      &.is-active {
        background-color: var(--c-black);
      }
    }
  }
}
@include from-landscape-min {
  .main-banner-wrapper {
    height: 44.1vw;
    max-height: 635px;
    margin: 0 auto 3rem;
    .main-banner-content {
      .main-banner-content__title-wrapper {
        top: calc(50% - (10.375rem / 2));
        bottom: unset;
        z-index: 1;
      }
    }
    .slider-bullets {
      display: none;
    }
  }
}
</style>

<style lang="scss">
// Ugly but effective in making the carousel responsive. Couldn't use * selector
// because it breaks child element style
.main-banner-wrapper {
  .main-banner-content {
    .sf-link {
      text-decoration: none;
    }
    .sf-carousel {
      max-height: 635px;
      .sf-carousel__wrapper {
        max-height: inherit;
        height: inherit;
        .glide.glide--carousel {
          max-height: inherit;
          height: inherit;
          .glide__track {
            max-height: inherit;
            height: inherit;
            .glide__slides.sf-carousel__slides {
              max-height: inherit;
              height: inherit;
              .sf-carousel-item.glide__slide {
                max-height: inherit;
                height: inherit;
                max-width: 720px;
                .carousel-item-content {
                  max-height: inherit;
                  height: inherit;
                  width: inherit;
                  .sf-image--wrapper {
                    max-height: inherit;
                    height: inherit;
                    width: 100%;
                    & > img {
                      width: 100%;
                      opacity: 1;
                      position: relative;
                    }
                    .sf-image {
                      max-height: inherit;
                      height: inherit;
                      object-fit: cover;
                      width: auto;
                      max-width: 100%;
                      margin: 0 auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 513px) and (max-width: $tablet-max) {
  .main-banner-wrapper {
    .main-banner-content {
      .sf-carousel {
        .sf-carousel__wrapper {
          .glide.glide--carousel {
            .glide__track {
              .glide__slides.sf-carousel__slides {
                .sf-carousel-item.glide__slide {
                  max-width: unset;
                  .carousel-item-content {
                    .sf-image--wrapper {
                      display: flex;
                      justify-content: center;
                      height: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 513px) {
  .main-banner-wrapper {
    .main-banner-content {
      .sf-carousel {
        height: 123.78vw;
      }
    }
  }
}
@include from-landscape-min {
  .main-banner-wrapper {
    .main-banner-content {
      .sf-carousel {
        max-height: 635px;
        height: inherit;
        &.mobile-only {
          display: none;
        }
      }
    }
  }
}
</style>
