var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"home"}},[_c('h1',{style:({ display: 'none' })},[_vm._v(_vm._s(_vm.metaTitle))]),_vm._v(" "),_vm._l((_vm.orderedHomepageBlocks),function(block){return _c('div',{key:block},[(
        _vm.homepageBlocksContent[block] &&
        _vm.homepageBlocksContent[block].active &&
        _vm.homepageBlocksContent[block].group_block_data &&
        Object.keys(_vm.homepageBlocksContent[block].group_block_data).length >
          0 &&
        _vm.homepageBlocksContent[block].group_block_data.data &&
        Object.keys(_vm.homepageBlocksContent[block].group_block_data.data)
          .length > 0 &&
        !_vm.loadingBlocks &&
        ((_vm.homepageBlocksContent[block].group_block_data.hasOwnProperty(
          'active'
        )
          ? _vm.homepageBlocksContent[block].group_block_data.active
          : true) ||
          _vm.hasItAndsIsActive(block))
      )?[_c(_vm.homepageBlocksAssociation[block],_vm._b({key:block,tag:"component",attrs:{"additional-data":{
          valid_segments: _vm.validSegments,
          date_range: _vm.homepageBlocksContent[block].date_range,
        }}},'component',_vm.homepageBlocksContent[block].group_block_data,false))]:(_vm.loadingBlocks)?_c('div',{staticClass:"hp-loader"}):_c('div')],2)}),_vm._v(" "),_vm._l((_vm.homepagePopupData),function(popup,index){return _c('PromoPopup',{key:("hp-popup--" + index),attrs:{"popup-data":popup}})}),_vm._v(" "),_c('Organization'),_vm._v(" "),_c('Website')],2)}
var staticRenderFns = []

export { render, staticRenderFns }