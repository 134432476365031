var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.promoBannerContent &&
    Object.keys(_vm.promoBannerContent).length > 0 &&
    _vm.unifiedUnixRange.start - _vm.nowUtc() < 0 &&
    _vm.unifiedUnixRange.end - _vm.nowUtc() > 0
  )?_c('div',{staticClass:"promo-hero-wrapper"},[_c('HeroSection',{staticClass:"promo-hero",attrs:{"title":_vm.promoBannerContent.text && _vm.promoBannerContent.text['big-text']
        ? _vm.promoBannerContent.text['big-text']
        : '',"has-header":true,"header-text":_vm.promoBannerContent.text && _vm.promoBannerContent.text['small-text']
        ? _vm.promoBannerContent.text['small-text']
        : '',"image-src":_vm.isDesktopOrTablet
        ? _vm.promoBannerContent.image.desktop
        : _vm.promoBannerContent.image.mobile || '/homepage/hero_banner.png',"image-alt":_vm.promoBannerContent.image.alt || 'Promo Banner',"link-label":_vm.promoBannerContent.link && _vm.promoBannerContent.link.label
        ? _vm.promoBannerContent.link.label
        : '',"link-target":_vm.promoBannerContent.link && _vm.promoBannerContent.link.target
        ? _vm.promoBannerContent.link.target
        : '',"link-url":_vm.promoBannerContent.link && _vm.promoBannerContent.link.url
        ? _vm.promoBannerContent.link.url
        : '',"full-image-link":true}})],1):_c('div',{staticClass:"featured"})}
var staticRenderFns = []

export { render, staticRenderFns }