<template>
  <div v-if="bannerItems.length > 0" class="featured">
    <HeroSection
      v-for="(bannerItem, index) in bannerItems"
      :title="bannerItem.text['big-text'] || ''"
      :description="bannerItem.text['small-text'] || ''"
      :has-header="false"
      :key="index"
      :image-src="
        bannerItem.images.desktop || '/icons/components/product_placeholder.svg'
      "
      :image-alt="bannerItem.images.alt || `Featured Image ${index + 1}`"
      :link-label="bannerItem.link.label || ''"
      :link-target="bannerItem.link.target || ''"
      :link-url="bannerItem.link.url || ''"
      loading="lazy"
    />
  </div>
  <div v-else class="featured" />
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import HeroSection from '~/components/HeroSection.vue';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'Featured',
  components: {
    HeroSection,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const bannerItems = computed(() => {
      return (
        props.data[
          props.data?.it?.text?.['big-text'] !== '' && locale === 'it'
            ? 'it'
            : 'en'
        ]?.filter((item) => item === null || true) || []
      );
    });
    return {
      bannerItems,
    };
  },
});
</script>

<style lang="scss" scoped>
@include from-landscape-min {
  .featured {
    column-gap: 1px;
    display: flex;
    .hero {
      width: 50%;
    }
  }
}
</style>

<style lang="scss">
@include from-landscape-min {
  .featured {
    .hero {
      .brand-alert-box {
        min-width: unset;
      }
    }
  }
}
</style>
