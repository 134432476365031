





































import { SfModal } from '@storefront-ui/vue';
import {
  defineComponent,
  onMounted,
  PropType,
  useContext,
} from '@nuxtjs/composition-api';
import { CrossIcon } from '~/components/General/Icons';
import { useUiState } from '~/composables';
import HTMLContent from '~/components/HTMLContent.vue';
import CustomButton from '~/components/General/CustomButton.vue';

type ButtonLink = {
  'link-target': string;
  'link-url': string;
  'link-label': string;
};
type PopupElementData = {
  active_desktop: boolean;
  active_mobile: boolean;
  position: string;
  content: string;
  title: string;
  link: ButtonLink;
};

export default defineComponent({
  name: 'PromoPopup',
  components: {
    SfModal,
    CustomButton,
    HTMLContent,
    CrossIcon,
  },
  props: {
    popupData: {
      type: Object as PropType<PopupElementData>,
      default: () => {},
    },
  },
  setup(props) {
    const {
      app: {
        $device: { isDesktop },
      },
    } = useContext();
    const { getIsPromoPopupActive, setPromoPopupActive, togglePromoPopup } =
      useUiState();

    onMounted(() => {
      setPromoPopupActive(
        props.popupData.position,
        isDesktop
          ? props.popupData.active_desktop
          : props.popupData.active_mobile
      );
    });

    return {
      getIsPromoPopupActive,
      togglePromoPopup,
    };
  },
});
