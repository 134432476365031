<template>
  <div id="home">
    <h1 :style="{ display: 'none' }">{{ metaTitle }}</h1>
    <div v-for="block in orderedHomepageBlocks" :key="block">
      <template
        v-if="
          homepageBlocksContent[block] &&
          homepageBlocksContent[block].active &&
          homepageBlocksContent[block].group_block_data &&
          Object.keys(homepageBlocksContent[block].group_block_data).length >
            0 &&
          homepageBlocksContent[block].group_block_data.data &&
          Object.keys(homepageBlocksContent[block].group_block_data.data)
            .length > 0 &&
          !loadingBlocks &&
          ((homepageBlocksContent[block].group_block_data.hasOwnProperty(
            'active'
          )
            ? homepageBlocksContent[block].group_block_data.active
            : true) ||
            hasItAndsIsActive(block))
        "
      >
        <component
          :key="block"
          :is="homepageBlocksAssociation[block]"
          v-bind="homepageBlocksContent[block].group_block_data"
          :additional-data="{
            valid_segments: validSegments,
            date_range: homepageBlocksContent[block].date_range,
          }"
        />
      </template>
      <div v-else-if="loadingBlocks" class="hp-loader" />
      <div v-else />
    </div>
    <PromoPopup
      v-for="(popup, index) in homepagePopupData"
      :popup-data="popup"
      :key="`hp-popup--${index}`"
    />
    <Organization />
    <Website />
  </div>
</template>

<script type="module">
import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch,
  useMeta,
  shallowRef,
  watch,
  onMounted,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@gemini/vsf-cache';
import MainBanner from '~/components/Homepage/MainBanner.vue';
import PromoBanner from '~/components/Homepage/PromoBanner.vue';
import FeaturedDesigners from '~/components/Homepage/FeaturedDesigners.vue';
import ProductsGrid from '~/components/Catalog/ProductsGrid.vue';
import Featured from '~/components/Homepage/Featured.vue';
import EditorialeSection from '~/components/Homepage/EditorialeSection.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { contentGetters, useContent, useUser } from '@gemini-vsf/composables';
import Organization from '~/components/StructuredData/Organization.vue';
import { useGetAlternateMatching } from '~/helpers/alternate/getAlternateMatching';
import { useGtm, useWindow, useKlaviyo } from '~/composables';
import { handleSeo } from '~/helpers/seo/handleSeo';
import PromoPopup from '~/components/Homepage/Popup.vue';
import Website from '~/components/StructuredData/Website.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    PromoPopup,
    MainBanner,
    PromoBanner,
    FeaturedDesigners,
    ProductsGrid,
    Featured,
    EditorialeSection,
    Organization,
    Website,
  },
  head: {},
  setup() {
    const { addTags } = useCache();
    const {
      $vsf: {
        $gemini: {
          config: { localesToMarkets },
        },
      },
    } = useContext();
    const trans = useI18n();
    const { locale } = useI18n();
    const { isDesktopOrTablet } = useWindow();
    const { loading: loadingUser, isAuthenticated } = useUser();
    const { blocks, loadBlocks } = useContent(`content-home-${trans.locale}`);
    // Key -> value pairs of block identifier and corresponding component
    const homepageBlocksAssociation = {
      'promo-banner': 'PromoBanner',
      'promo-banner-two': 'PromoBanner',
      'main-banner': 'MainBanner',
      'featured-designers': 'FeaturedDesigners',
      'homepage-editorial': 'EditorialeSection',
      'new-arrivals': 'ProductsGrid',
      'bottom-banner': 'Featured',
    };
    const orderedHomepageBlocks = ref(Object.keys(homepageBlocksAssociation));
    const homepageBlocksContent = ref({});
    const homepagePopupData = ref({});
    const metaTitle = trans.t(
      'biffi.com | Luxury Fashion | Clothing, Bags and Shoes'
    );
    const metaDescription = trans.t(
      'biffi.com | Luxury Fashion | Clothing, Bags and Shoes'
    );
    const { pageGtmPush } = useGtm();

    const { getAlternateArray } = useGetAlternateMatching();

    const { getCanonical } = handleSeo();
    const seoAlternateurls = ref([]);
    const { klaviyoPagePush } = useKlaviyo();

    const getGeminiDataFunction = () => {
      seoAlternateurls.value = getAlternateArray('', '');
      return JSON.parse(JSON.stringify(seoAlternateurls.value));
    };
    const refProductDataSeoAlternate = computed(() => getGeminiDataFunction());
    const canonicalLink = computed(() => getCanonical());

    const validGroup = shallowRef();
    const validSegments = ref();
    const userStatus = computed(() => {
      return !loadingUser.value && !isAuthenticated.value ? 'guest' : 'logged';
    });
    const loadingBlocks = shallowRef(true);

    const setValidGroup = () => {
      validGroup.value = 'group_0';
      Object.keys(
        homepageBlocksContent.value?.['markets-association'] || {}
      )?.find((group) => {
        if (
          group !== 'valid_segments' &&
          homepageBlocksContent.value?.['markets-association'][group].indexOf(
            localesToMarkets?.[trans.locale]?.split('|')?.[0]
          ) !== -1
        ) {
          validGroup.value = group;
          return true;
        }
        return false;
      });
    };

    const populateBlockDataRef = (blockData) => {
      Object.keys(homepageBlocksAssociation).forEach((identifier) => {
        homepageBlocksContent.value = {
          ...homepageBlocksContent.value,
          [identifier]: {
            group_block_data:
              blockData[identifier]?.[validGroup.value]?.[
                // Always use guest data if logged has no value
                blockData[identifier][validGroup.value]?.logged?.active
                  ? userStatus.value
                  : 'guest'
              ],
            active: blockData[identifier]?.active,
            position: blockData[identifier]?.position,
            date_range: blockData[identifier]?.[validGroup.value]?.date_range,
          },
        };
      });
    };

    useFetch(async () => {
      await loadBlocks({
        identifiers: [
          ...Object.keys(homepageBlocksAssociation),
          `popup-${trans.locale === 'it' ? 'it' : 'en'}`,
          'markets-association',
        ],
      });
      addTags([{ prefix: CacheTagPrefix.View, value: 'homepage' }]);
    });

    onMounted(() => {
      klaviyoPagePush(metaTitle);
      if (trans.locale === 'it') {
        pageGtmPush(metaTitle, 'homepage');
      }
    });

    // use bool to trigger blocks data parse only once per page load
    const blocksOk = ref(false);
    // watch both user and blocks to be sure all parameters of localized
    // blocks are set
    const tempWatch = watch([loadingUser, blocks], async () => {
      if (!loadingUser.value && !!blocks.value && !blocksOk.value) {
        blocksOk.value = true;
        await loadBlocks({
          identifiers: [
            ...Object.keys(homepageBlocksAssociation),
            `popup-${trans.locale === 'it' ? 'it' : 'en'}`,
            'markets-association',
          ],
        });
        const blockData = await contentGetters.getCmsBlockContent(blocks.value);
        homepageBlocksContent.value = {
          ...homepageBlocksContent.value,
          'markets-association': blockData['markets-association'],
        };
        homepagePopupData.value =
          blockData[`popup-${trans.locale === 'it' ? 'it' : 'en'}`]?.data;
        validSegments.value =
          homepageBlocksContent.value?.['markets-association']?.valid_segments;
        // Check all markets array to find in which group the current market is contained
        setValidGroup();
        // Create an object with data of all blocks for the current market
        populateBlockDataRef(blockData);
        // Sort blocks by position fetched from backoffice
        orderedHomepageBlocks.value.sort((a, b) => {
          if (
            homepageBlocksContent.value?.[a]?.position &&
            homepageBlocksContent.value?.[b]?.position &&
            homepageBlocksContent.value?.[a]?.position >
              homepageBlocksContent.value?.[b]?.position
          ) {
            return 1;
          }
          if (
            homepageBlocksContent.value?.[a]?.position &&
            homepageBlocksContent.value?.[b]?.position &&
            homepageBlocksContent.value?.[a]?.position <
              homepageBlocksContent.value?.[b]?.position
          ) {
            return -1;
          }
          return 0;
        });
        loadingBlocks.value = false;
        tempWatch();
      }
    });

    const hasItAndsIsActive = (blockIdentifier) => {
      return (
        homepageBlocksContent.value?.[blockIdentifier].group_block_data?.data
          ?.it?.active && locale === 'it'
      );
    };

    useMeta(() => ({
      title: metaTitle,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: metaTitle || 'Home - Biffi Boutiques',
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: metaTitle || 'Home - Biffi Boutiques',
        },
        {
          hid: 'description',
          name: 'description',
          content: metaDescription || 'Home - Biffi Boutiques',
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: metaDescription || 'Home - Biffi Boutiques',
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'index, follow',
        },
      ],
      link: [...refProductDataSeoAlternate.value, canonicalLink.value],
    }));

    return {
      metaTitle,
      metaDescription,
      getAlternateArray,
      seoAlternateurls,
      isDesktopOrTablet,
      orderedHomepageBlocks,
      homepageBlocksAssociation,
      homepageBlocksContent,
      validSegments,
      loadingBlocks,
      homepagePopupData,
      blocks,
      canonicalLink,
      hasItAndsIsActive,
    };
  },
});
</script>

<style lang="scss" scoped>
.article-meta h4 a {
  color: #111111;
  font-weight: 600;
  font-size: 20px;
}

.article-meta {
  margin-top: 10px;
}

.article-item__meta-item:not(:last-child)::after {
  display: inline-block;
  content: '';
  width: 5px;
  height: 5px;
  margin: -1px 10px 0 10px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.4);
  vertical-align: middle;
}

#home {
  box-sizing: border-box;
  padding: 0 var(--spacer-sm);
  width: 100%;
  padding: 0;
  margin: 0 auto;

  .hp-loader {
    height: 80vh;
    width: 100%;
    box-sizing: border-box;
    background: var(--c-text-disabled);
    position: relative;
    overflow: hidden;
    margin: var(--spacer-xs) 0;
    z-index: 0;
  }
  .hp-loader::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: left-to-right 2.5s linear infinite;
    background-image: linear-gradient(
      90deg,
      var(--c-text-disabled) 0px,
      var(--c-light) var(--spacer-xl),
      var(--c-text-disabled) var(--spacer-2xl)
    );
  }

  @keyframes animloader {
    to {
      background-position: 185px 0, center 0, center 115px, center 142px;
    }
  }
}

.hero-section {
  margin: var(--spacer-xl) auto var(--spacer-lg);

  ::v-deep .sf-link:hover {
    color: var(--c-white);
  }

  @include for-desktop {
    margin: var(--spacer-xl) auto var(--spacer-2xl);
  }
}

.banner-grid {
  --banner-container-width: 50%;
  margin: var(--spacer-xl) 0;

  ::v-deep .sf-link:hover {
    color: var(--c-white);
  }

  @include for-desktop {
    margin: var(--spacer-2xl) 0;
    ::v-deep .sf-link {
      --button-width: auto;
    }
  }
}

.banner {
  &__tshirt {
    background-position: left;
  }

  &-central {
    @include for-desktop {
      --banner-container-flex: 0 0 70%;
    }
  }
}

.similar-products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--spacer-2xs);
  --heading-padding: 0;
  border-bottom: 1px var(--c-light) solid;
  @include for-desktop {
    border-bottom: 0;
    justify-content: center;
    padding-bottom: 0;
  }
}

.call-to-action {
  background-position: right;
  margin: var(--spacer-xs) 0;
  @include for-desktop {
    margin: var(--spacer-xl) 0 var(--spacer-2xl) 0;
  }
}

.products {
  margin-top: var(--spacer-base);
}

.carousel {
  margin: 0 calc(-1 * var(--spacer-sm)) 0 0;
  @include for-desktop {
    margin: 0;
  }

  &__item {
    margin: 1.375rem 0 2.5rem 0;
    @include for-desktop {
      margin: var(--spacer-xl) 0 var(--spacer-xl) 0;
    }

    &__product {
      --product-card-add-button-transform: translate3d(0, 30%, 0);
    }
  }
}
</style>
